<template>
  <div class="w-100 h-auto">
    <b-modal ref="edit-feature-modal" hide-footer title="Edit Feature">
      <!-- <feature-edit-form
        :closeAction="hideEditFeatureModal"
        :featureId="selectedSubscriptionId"
        :reloadParent="load"
      /> -->
    </b-modal>
    <b-sidebar
      id="sidebar-feature-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="420px"
    >
      <!-- <feature-details-sidebar
        :closeAction="closefeatureDetailsSidebar"
        :featureId="selectedSubscriptionId"
      /> -->
    </b-sidebar>
    <div class="card">
      <div class="card-header pb-75 mx-0 py-0" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12 px-0 mx-0"
          >
            <h3 class="mb-50 mt-1 font-weight-bold">Subscription History</h3>
            <p class="">Manage your subscriptions.</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
            >
              <b-button
                size="sm"
                variant="success"
                @click="handleNewSubscriptionClick"
                ><feather-icon icon="PlusIcon" class="mr-50" />New
                Subscription</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search name..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <div class="position-relative" style="min-height: 50vh">
        <b-overlay
          variant="white"
          :show="showOverlay"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-start"
          >
            <template v-if="Object.keys(subscriptions).length > 0">
              <subscribed-plan-card
                v-for="(subscription, key) in subscriptions"
                :key="key"
                :subscription="subscription"
              />
            </template>
            <template v-else>
              <div class="card">
                <div class="card-header pt-3">
                  <div
                    class="d-flex flex-column align-items-center justify-content-center w-100"
                  >
                    <feather-icon
                      icon="SlashIcon"
                      class="mb-1 text-secondary"
                      size="32"
                    />
                    <h3 class="font-weight-bolder">Subscriptions Empty</h3>
                    <p>You have no subscriptions.</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </b-overlay>
      </div>
      <div class="card-body my-0 py-0" v-if="Object.keys(subscriptions).length > 0">
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="filters.page"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
  BSidebar,
  BOverlay,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import SubscriptionMixins from "@/mixins/SubscriptionMixins";
import EmptyTableSection from "../../components/EmptyTableSection.vue";
import SubscribedPlanCard from "./SubscribedPlanCard.vue";

export default {
  mixins: [ResponseMixins, SubscriptionMixins],
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BSidebar,
    BOverlay,
    EmptyTableSection,
    SubscribedPlanCard,
  },
  data() {
    return {
      selectedSubscriptionId: null,
      pagination_pos: "center",

      showOverlay: false,
      pagination: {
        perPage: 10,
        total: 1,
        from: 1,
      },

      filters: {
        search: null,
        page: 1,
        tenant_id:null,
      },

      subscriptions: {},
    };
  },
  watch: {
    filters: {
      handler: function (newValues) {
        this.getSubscriptionsAndSetData(newValues);
      },
      deep: true,
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.$set(this.filters,'tenant_id', this.$route.params.id)
      this.getSubscriptionsAndSetData(this.filters);
      
    },

    handleNewSubscriptionClick() {
      this.$router.push(`/new-subscription/${this.$route.params.id}`);
    },

    handleEditFeatureClick(id) {
      this.selectedSubscriptionId = id;
      this.showEditFeatureModal();
    },

    showEditFeatureModal() {
      this.$refs["edit-feature-modal"].show();
    },
    hideEditFeatureModal() {
      this.$refs["edit-feature-modal"].hide();
    },

    handleDetailViewClick(id) {
      this.selectedSubscriptionId = id;
      this.openfeatureDetailsSidebar();
    },

    openfeatureDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-feature-details");
    },

    closefeatureDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-feature-details");
    },

    getSubscriptionsAndSetData(params) {
      this.getSubscriptions(params)
        .then((res) => {
          console.log(res);
          const newSubscriptions = {};
          res.data.data.data.forEach((feature) => {
            newSubscriptions[feature._id] = feature;
          });
          this.subscriptions = newSubscriptions;
          this.$set(this.filters, "page", res.data.data.current_page);
          this.$set(this.pagination, "perPage", res.data.data.per_page);
          this.$set(this.pagination, "total", res.data.data.total);
          this.$set(this.pagination, "from", res.data.data.from);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>
  
<style lang="css" scoped>
.table th {
  text-transform: none;
}
</style>
  
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>