<template>
  <div
    class="w-100 h-auto rounded mb-75"
    :class="{ 'bg-light-primary border-primary': featuresListVisible }"
  >
    <div class="w-100 card rounded border px-1 py-25 mb-0" style="height: 68px">
      <div
        class="w-100 h-100 d-flex flex-row align-items-center justify-content-between"
      >
        <div
          class="d-flex align-items-center justify-content-start"
          style="width: 40%"
        >
          <div class="h-100 d-flex align-items-center justifi-content-center">
            <b-badge
              v-if="getBalanceDays(subscription.effective_to) > 0"
              v-b-tooltip.top.hover.v-success
              title="Subscription is active"
              pill
              variant="success"
              ><feather-icon width="28" height="28" icon="CheckCircleIcon"
            /></b-badge>
            <b-badge
              v-else
              v-b-tooltip.top.hover.v-danger
              title="Subscription has expired"
              pill
              variant="danger"
              ><feather-icon width="28" height="28" icon="XCircleIcon"
            /></b-badge>
          </div>
          <div
            class="d-flex flex-column align-items-start justify-content-start ml-1"
          >
            <label>Plan</label>
            <h6 class="mb-0 font-weight-bold">{{ subscription.plan.title }}</h6>
          </div>
        </div>

        <div
          class="d-flex flex-row align-items-center justify-content-between h-100"
          style="width: 58%"
        >
          <div
            class="d-flex flex-column align-items-start justify-content-center h-100 mr-1 cursor-pointer"
            :id="`popover-validity-${subscription._id}`"
          >
            <div class="w-100 d-flex align-items-center justify-content-center">
              <b-badge
                style="width: 180px"
                class="px-50 py-25 d-flex flex-column align-items-center justify-content-between position-relative"
                :variant="`light-${getVariant(
                  Math.floor(
                    getMapedValue(
                      getBalanceDays(subscription.effective_to),
                      1,
                      parseInt(subscription.plan_price.duration),
                      1,
                      5
                    )
                  )
                )}`"
              >
                <div
                  class="w-100 d-flex align-items-center justify-content-start"
                >
                  <small class="font-weight-bold mr-50">
                    <b-badge variant="dark"
                      >{{ subscription.plan_price.name }}
                    </b-badge>
                  </small>
                  <span class="text-center">
                    <label
                      v-if="getBalanceDays(subscription.effective_to) > 0"
                      class="font-weight-bolder mb-0 text-center"
                      >{{ getBalanceDays(subscription.effective_to) }} days
                      left</label
                    >
                    <b-badge v-else variant="danger">Expired</b-badge>
                  </span>
                </div>

                <b-progress
                  style="height: 10px"
                  :value="
                    getMapedValue(
                      getBalanceDays(subscription.effective_to),
                      1,
                      parseInt(subscription.plan_price.duration),
                      1,
                      100
                    )
                  "
                  :max="100"
                  animated
                  striped
                  class="w-100 mt-25"
                  :variant="
                    getVariant(
                      Math.floor(
                        getMapedValue(
                          getBalanceDays(subscription.effective_to),
                          1,
                          parseInt(subscription.plan_price.duration),
                          1,
                          5
                        )
                      )
                    )
                  "
                />
              </b-badge>
            </div>

            <!-- <div
            class="w-100 d-flex align-items-center justify-content-center mt-25"
          >
            <label class="font-weight-bolder mb-0"
              >{{ getBalanceDays(subscription.effective_to) }} days left</label
            >
          </div> -->
          </div>

          <div
            class="d-flex align-items-center justify-content-start ml-3 mr-auto"
          >
            <div
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <label class="mb-0">Cost</label>
              <h3
                class="mb-0 text-center font-weight-bolder text-primary align-middle"
              >
                <feather-icon size="20" icon="DollarSignIcon" /><span
                  class="align-middle"
                  >{{ subscription.plan_price.tariff }}</span
                >
              </h3>
            </div>
          </div>

          <!-- <b-button variant="primary" class="mr-1"
          ><feather-icon icon="ClockIcon" class="mr-50" />Renew</b-button
        > -->

          <div class="d-flex flex-row align-items-center justify-content-end">
            <b-button
              @click="handleCustomizeSubscriptionClick(subscription._id)"
              variant="dark"
              size="sm"
              class="mr-50"
              ><span class="text-center align-middle">
                <feather-icon icon="SettingsIcon" class="mr-50" /><span
                  >Customize</span
                >
              </span></b-button
            >

            <b-button
              v-b-tooltip.top.hover.v-warning
              title="Toggle Features"
              :aria-expanded="featuresListVisible ? 'true' : 'false'"
              :aria-controls="`collapse-features-${subscription._id}`"
              @click="
                () => {
                  featuresListVisible = !featuresListVisible;
                }
              "
              variant="flat-dark"
              size="sm"
              ><feather-icon
                size="20"
                :icon="
                  featuresListVisible === true
                    ? 'ChevronUpIcon'
                    : 'ChevronDownIcon'
                "
            /></b-button>

            <!-- <b-button variant="flat-secondary" size="sm"
              ><feather-icon size="20" icon="MoreHorizontalIcon"
            /></b-button> -->
          </div>
        </div>
      </div>

      <b-popover
        :target="`popover-validity-${subscription._id}`"
        triggers="hover"
        placement="left"
      >
        <template v-slot:title>
          <span>Validity</span>
        </template>
        <div class="d-flex flex-column align-items-start justify-content-start">
          <div
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <label class="font-weight-bolder">Valid From</label>
            <p class="mb-0">{{ subscription.effective_from | moment }}</p>
          </div>

          <div
            class="d-flex flex-column align-items-start justify-content-start mt-1"
          >
            <label class="font-weight-bolder">Valid Till</label>
            <p class="mb-0">{{ subscription.effective_to | moment }}</p>
          </div>
        </div>
      </b-popover>
    </div>
    <b-collapse
      :id="`collapse-features-${subscription._id}`"
      v-model="featuresListVisible"
      class="w-100"
    >
      <div class="table-responsive mb-0 w-100" style="max-height: 340px">
        <table role="table" class="table table-bordered table-condensed">
          <thead role="rowgroup">
            <tr role="row">
              <th scope="col">#</th>
              <th scope="col">Feature Name</th>
              <th scope="col">Description</th>
              <th scope="col" class="text-center">Quantity</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <template v-if="subscription.features.length > 0">
              <tr
                role="row"
                v-for="(feature, index) in subscription.features"
                :key="feature._id['$oid']"
              >
                <td scope="row">
                  {{ index + 1 }}
                </td>

                <td role="cell" style="max-width: 20vw">
                  <div
                    class="d-flex flex-column align-items-start justify-content-start w-100"
                  >
                    <p class="mb-0 font-weight-bold w-100">
                      {{ `${feature.title}` }}
                    </p>
                  </div>
                </td>

                <td role="cell" class="w-auto">
                  <div
                    class="d-flex flex-column align-items-start justify-content-start w-100"
                  >
                    <p class="mb-0 font-weight-bold w-100">
                      {{ `${feature.description}` }}
                    </p>
                  </div>
                </td>

                <td role="cell">
                  <div
                    class="d-flex align-items-center justify-content-center w-100"
                    v-if="feature.unit && feature.unit != null"
                  >
                    <div
                      v-if="
                        feature.unit &&
                        feature.unit == 'count' &&
                        feature.quantity
                      "
                      class="d-flex flex-column align-items-center justify-content-center"
                    >
                      <label class="font-weight-bolder text-primary"
                        >Count</label
                      >
                      <b-badge variant="dark">{{ feature.quantity }}</b-badge>
                    </div>

                    <div
                      v-else-if="
                        feature.unit &&
                        feature.unit == 'credit' &&
                        feature.tariff
                      "
                      class="d-flex flex-column align-items-center justify-content-center"
                    >
                      <label class="font-weight-bolder text-primary"
                        >Credits Per Unit</label
                      >
                      <b-badge variant="dark">{{ feature.tariff }}</b-badge>
                    </div>

                    <div
                      v-else-if="feature.unit && feature.unit == 'boolean'"
                      class="d-flex flex-column align-items-start justify-content-center"
                    >
                      <label class="font-weight-bolder text-success"
                        >Enabled</label
                      >
                      <b-form-checkbox
                        checked="true"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        :disabled="true"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <empty-table-section title="Features Empty">
                <template #content>
                  <p class="font-weight-bold text-center">
                    This subscription contains no features.
                  </p>
                </template>
              </empty-table-section>
            </template>
          </tbody>
        </table>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  BBadge,
  VBTooltip,
  BProgress,
  BPopover,
  VBPopover,
  BButton,
  BCollapse,
  BFormCheckbox,
} from "bootstrap-vue";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
import moment from "moment";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  components: {
    FeatherIcon,
    BBadge,
    moment,
    BProgress,
    BPopover,
    BButton,
    BCollapse,
    BFormCheckbox,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("Do MMMM YYYY");
    },
  },

  data() {
    return {
      featuresListVisible: false,
    };
  },

  mounted() {
    console.log(this.getBalanceDays(this.subscription.effective_to));
  },

  methods: {
    handleCustomizeSubscriptionClick(id) {
      this.$router.push(`/customize-subscription/${id}`);
    },

    getVariant(status) {
      switch (status) {
        case 5:
          return "primary";
        case 4:
          return "primary";
        case 3:
          return "primary";
        case 2:
          return "warning";
        case 1:
          return "danger";
        case 0:
          return "danger";
        default:
          return "primary";
      }
    },
    getMapedValue(value, in_min, in_max, out_min, out_max) {
      const newValue =
        ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
      return newValue;
    },
    percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    },

    getDateDifference(startDate, endDate) {
      const timestamp1 = new Date(startDate).getTime();
      const timestamp2 = new Date(endDate).getTime();

      const timeDifference = Math.abs(timestamp2 - timestamp1);
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return daysDifference;
    },

    getBalanceDays(endDate) {
      const timestamp1 = new Date().getTime();
      const timestamp2 = new Date(endDate).getTime();

      const timeDifference = timestamp2 - timestamp1;
      if (timeDifference > 0) {
        const daysDifference = Math.ceil(
          timeDifference / (1000 * 60 * 60 * 24)
        );
        return daysDifference;
      }

      return 0;
    },
  },
};
</script>

<style>
</style>


<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.55rem 0.75rem;
}
</style>
  
<style lang="css" scoped>
.table th {
  text-transform: none;
  position: sticky;
  top: 0;
  z-index: 99;
}
</style>